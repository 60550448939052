nav {
    padding-bottom:200px;
    
    /* width: 100%;
    height: 90px; */
    background: -webkit-linear-gradient(#ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00);
    background: -o-linear-gradient(#ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00);
    background: linear-gradient(#ffffff00,#ffffff00,#ffffff00,#ffffff00, #ffffff00);
    /* background: -webkit-linear-gradient(#3f9a15, #388813, #3f9a15, #388813, #3f9a15);
    background: -o-linear-gradient(#3f9a15, #388813, #3f9a15, #388813, #3f9a15);
    background: linear-gradient(#3f9a15, #388813, #3f9a15, #388813, #3f9a15);  */
    /* border-radius: 6px !important; */
    /* /* -moz-border-radius: 1000px !important; */
    } 
.nav-link{
    /* color: rgb(87, 86, 86) !important; */
   font-size: 1.5em !important;
   font-family: sans-serif;
   
}
.navbar-inner {
    background:transparent;
}