html {
  font-size: 62.5%;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  font-size: $m-size;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}
