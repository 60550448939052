h1 {
    font-family: 'Raleway', sans-serif;
    background: rgb(76, 78, 78);
    color: white;
    padding: 2rem 2rem 2rem;
    }
    #title {
    font-family: 'Raleway', sans-serif;;
    font-size: 7em;
    color: white;
    -webkit-box-shadow: 0 4px 50px rgba(0,0,0,.18);
    box-shadow: 10px 40px 500px
    rgba(0,0,0,.18);
    padding: 4rem 3rem 4rem;
    text-align: center;
    box-sizing: border-box;
    
    }
    #sub-text {
    font-family: 'Raleway', sans-serif;;
    font-size: 1em;
    color: black;
    }
    
    #community-back {
    background-color:#b3d9ff ;
    }
    
    #sub-img {
    fill: bisque;
    }