
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* .App .slideShow {
  background-image: url('./components/master/slideShow.js') 
} */
.App .pastsuccess {
  background-image: url('./components/images/NatureLake.jpg');
}
.App .whatWedo {
  background-image: url('./components/images/NatureLake.jpg');
}
.App .results {
  background-image: url('./components/images/redFlowers.jpg');
}
.App .red {
  background: #DC143C;
}
.App .orange {
  background: #FF8C00;
}
.App .yellow {
  background: #FFD700;
}
.App .green {
  background: rgba(54, 54, 54, 0.788);
}
.App .blue {
  background: #4682B4;
}
.App .purple {
  background: #9932CC;
}

/* .App {

  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;
  background: #222;

  font-family: 'Courier New', serif;

  /* text-align: center; 

}
.Slideshow .page {
  top: 0px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 50px;
  text-transform: uppercase;

}
.App .page {

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 50px;
  text-transform: uppercase;

}

.App .page h2 span:first-of-type {
  font-weight: 100;
  color: rgba(0, 0, 0, .5);
}

.App .page h2 span:last-of-type {
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
}
/* .App .slideShow {
  background-image: url('./components/master/slideShow.js') 
} 
.App .pastsuccess {
  background-image: url('./components/images/NatureLake.jpg');
}
.App .whatWedo {
  background-image: url('./components/images/NatureLake.jpg');
}
.App .results {
  background-image: url('./components/images/redFlowers.jpg');
}
.App .red {
  background: #DC143C;
}
.App .orange {
  background: #FF8C00;
}
.App .yellow {
  background: #FFD700;
}
.App .green {
  background: #6B8E23;
}
.App .blue {
  background: #4682B4;
}
.App .purple {
  background: #9932CC;
}
.App .controls,
.App .pagination {

  position: absolute;

  padding: 1vw;

  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 50px rgba(0, 0, 0, .25);

}

.App .controls {
  top: 1vw;
  right: 1vw;
}

.App .controls h3 {
  font-size: 16px;
  margin: 0 0 10px;
}

.App .pagination {
  bottom: 1vw;
  left: 1vw;
}

@media (max-width: 768px) {

  .App .page {
      font-size: 22px;
  }

  .App .controls {
      top: 2vw;
      right: 2vw;
      padding: 2vw;
  }

  .App .controls h3 {
      margin: 0 0 5px;
  }

  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } 

}
/* 

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} 
 */